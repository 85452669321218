@import url("https://fonts.googleapis.com/css2?family=Exo+2:wght@300;700;900&display=swap");
* {
  box-sizing: border-box;
}

:root {
  --gold: #ffb338;
  --light-shadow: #77571d;
  --dark-shadow: #3e2904;
}

.wrapper {
  display: grid;
  grid-template-areas: "overlap";
  // place-content: center;
  text-transform: uppercase;
  height: auto;
}
.wrapper > div {
  background-clip: text;
  -webkit-background-clip: text;
  color: #363833;
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  //font-size: clamp(3em, 18vw, 15rem);
  grid-area: overlap;
  letter-spacing: 1px;
  -webkit-text-stroke: 4px transparent;
}
div.bg {
  background-image: repeating-linear-gradient(
    105deg,
    var(--gold) 0%,
    var(--dark-shadow) 5%,
    var(--gold) 12%
  );
  color: transparent;
  filter: drop-shadow(5px 15px 15px black);
  transform: scaleY(1.05);
  transform-origin: top;
}
div.fg {
  background-image: repeating-linear-gradient(
    5deg,
    var(--gold) 0%,
    var(--light-shadow) 23%,
    var(--gold) 31%
  );
  color: #1e2127;
  transform: scale(1);
}

.container1-entering {
  transform: scale(1.5);
  opacity: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1.5);
}

.container1-entered,
.container1-exiting {
  top: 4rem;
  left: 4rem;
  transform: scale(1);
  transition: all 5s;
}

.container2-entering {
  opacity: 0;
}

.container2-entered,
.container2-exiting {
  opacity: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: opacity 2s;
}
